<template>
 

<div >
    <div>
        <h4>{{dashboardTypeName}}</h4>
        <div v-if="domoEmbedId==undefined">

        </div>
        <iframe id="domoIFrame" v-bind:src="domoEmbedUrl + domoEmbedId" width="100%" height="9500px" marginheight="0" marginwidth="0" frameborder="0"></iframe>
    </div>
    
</div>

</template>

<script>  

export default {
    
    created() {
        this.showDashboard();   
    },

    updated() {
        this.showDashboard();
    },

    data() {
        return{
            domoEmbedUrl: "https://public.domo.com/embed/pages/",
            domoEmbedId: "",
            dashboardType: 0,
            dashboardTypeName: "Dashboard"
        };
    },

    methods: {
        getDashboardTypeName: function () {
            return ["Dashboard","Forecast","Scorecard","Routing","Allocation","Triage","Billing"][this.dashboardType-1];
        },

        showDashboard: function() {
            this.domoEmbedId = this.$route.params.domoEmbedId;
            this.dashboardType = parseInt(this.$route.params.dashType);
            this.dashboardTypeName = this.getDashboardTypeName()
        }
    }
}
 



</script>

<style lang="scss" scoped>
	
</style>
